<template>
  <div>
    <b-row>
      <b-col cols="2"><b>Segment ID: </b></b-col>
      <b-col cols="4">{{ this.detail.id }}</b-col>
      <b-col cols="2"><b>Tên Segment: </b></b-col>
      <b-col cols="4">{{ this.detail.title }}</b-col>
    </b-row>
    <br />
    <b-row>
      <b-col cols="2"><b>Số lượng User: </b></b-col>
      <b-col cols="4">{{ this.detail.segment_users_count }}</b-col>
      <b-col cols="2"><b>Trạng thái: </b></b-col>
      <b-col cols="4">
        <b-badge v-if="this.detail.status === STATUS_FALSE" variant="danger"
          >False
        </b-badge>
        <b-badge
          v-if="this.detail.status === STATUS_PENDING"
          variant="secondary"
          >Pending
        </b-badge>
        <b-badge v-if="this.detail.status === STATUS_PROCESS" variant="warning"
          >Process
        </b-badge>
        <b-badge v-if="this.detail.status === STATUS_SUCCESS" variant="success"
          >Success
        </b-badge>
      </b-col>
    </b-row>
    <br />
    <b-row>
      <b-col cols="2"><b>Mô tả: </b></b-col>
      <b-col cols="4">{{ this.detail.description }}</b-col>
      <b-col cols="2"><b>Segment loại trừ: </b></b-col>
      <b-col cols="4" class="row">
        <span v-for="(segmentDiff, index) in segmentDiffTitle" :key="index">
          {{ segmentDiff }}
        </span></b-col
      >
    </b-row>
    <br />
    <b-row>
      <b-col cols="2"><b>Danh sách user: </b></b-col>
      <b-col cols="10"
        ><a
          :href="'#/tools/config-segment/segment-user/' + this.detail.id"
          target="_blank"
          >Danh sách User</a
        ></b-col
      >
    </b-row>
    <br />
    <hr />
    <br />
    <h4>Tiêu chí phân loại</h4>
    <!--Show config theo khách hàng-->
    <div class="div-user" v-if="show.is_user && this.user_props != null">
      <b-card>
        <b-card-text>
          <h4>Theo khách hàng</h4>
          <b-row>
            <b-col v-if="typeof this.user_props === 'object' && this.user_props.hasOwnProperty('register_time')">
              <b>Từ ngày: </b> {{ this.user_props.register_time.start_time }}
            </b-col>
            <b-col v-if="typeof this.user_props === 'object' && this.user_props.hasOwnProperty('register_time')">
              <b>Đến ngày: </b> {{ this.user_props.register_time.end_time }}
            </b-col>
            <b-col v-if="typeof this.user_props === 'object' && this.user_props.hasOwnProperty('kyc')">
              <b>Xác thực: </b>
              {{ this.user_props.kyc ? "Xác thực" : "Chưa xác thực" }}
            </b-col>
          </b-row>
        </b-card-text>
      </b-card>
      <br />
    </div>
    <!--Show config theo bank-->
    <div class="div-user" v-if="show.is_bank && this.bank_props != null">
      <b-card>
        <b-card-text>
          <h5>Theo ngân hàng</h5>
          <table class="table">
            <tbody>
              <tr v-for="(bank, key) in this.bank_props" :key="key">
                <div class="OR" v-if="Array.isArray(bank)">
                  <tr v-for="(bank2, key) in bank" :key="key">
                    <th scope="row">{{ bank2.condition }}</th>
                    <td><b>TTLK:</b></td>
                    <td>
                      <span v-if="bank2.affiliated === 'LINK'">Liên kết</span
                      ><span v-else>Chưa liên kết</span>
                    </td>
                    <td v-if="bank2.affiliated === 'LINK'"><b>Từ ngày:</b></td>
                    <td v-else><b>User đăng nhập ví từ ngày:</b></td>
                    <td>{{ bank2.register_time.start_time }}</td>
                    <td><b>Đến ngày:</b></td>
                    <td>{{ bank2.register_time.end_time }}</td>
                    <span v-if="bank2.affiliated === 'LINK'">
                      <td><b>Ngân hàng:</b></td>
                      <td>
                        <span
                          v-for="valueBank in bank2.value"
                          :key="valueBank.value"
                          >{{ valueBank.text }} ,</span
                        >
                      </td>
                      <td><b>Loại liên kết:</b></td>
                      <td>
                        <span v-if="bank2.linkType === 'DIRECT_LINK'"
                          >Liên kết trực tiếp</span
                        ><span v-else>Liên kết qua NAPAS</span>
                      </td>
                    </span>
                  </tr>
                </div>
                <div v-else>
                  <th scope="row">{{ bank.condition }}</th>
                  <td><b>TTLK:</b></td>
                  <td>
                    <span v-if="bank.affiliated === 'LINK'">Liên kết</span
                    ><span v-else>Chưa liên kết</span>
                  </td>
                  <td v-if="bank.affiliated === 'LINK'"><b>Từ ngày:</b></td>
                  <td v-else><b>User đăng nhập ví từ ngày:</b></td>
                  <td>{{ bank.register_time.start_time }}</td>
                  <td><b>Đến ngày:</b></td>
                  <td>{{ bank.register_time.end_time }}</td>
                  <span v-if="bank.affiliated === 'LINK'">
                    <td><b>Ngân hàng:</b></td>
                    <td>
                      <span
                        v-for="valueBank in bank.value"
                        :key="valueBank.value"
                        >{{ valueBank.text }} ,</span
                      >
                    </td>
                    <td><b>Loại liên kết:</b></td>
                    <td>
                      <span v-if="bank.linkType === 'DIRECT_LINK'"
                        >Liên kết trực tiếp</span
                      ><span v-else>Liên kết qua NAPAS</span>
                    </td>
                  </span>
                </div>
              </tr>
            </tbody>
          </table>
        </b-card-text>
      </b-card>
      <br />
    </div>
    <!--Show config theo login details-->
    <div class="div-login" v-if="show.isLoginSq && this.loginsequent != null">
      <b-card>
        <b-card-text>
          <h5>Tần suất đăng nhập</h5>
          <table class="table">
            <tbody>
              <tr v-for="(lg, key) in this.loginsequent" :key="key">
                <div class="OR" v-if="Array.isArray(lg)">
                  <tr v-for="(lg2, key) in lg" :key="key">
                    <th scope="row">{{ lg2.condition }}</th>
                    <th>Từ :</th>
                    <td>{{ lg2.start_time }}</td>
                    <th>Đến :</th>
                    <td>{{ lg2.end_time }}</td>
                    <th>Loại điều kiện :</th>
                    <td>{{ convertLoginSqCond(lg2.key) }}</td>
                    <th v-if="showTotal(lg2)">Số lần :</th>
                    <td v-if="showTotal(lg2)">{{ lg2.op }} {{ lg2.total }}</td>
                  </tr>
                </div>
                <div v-else>
                  <th scope="row">{{ lg.condition }}</th>
                  <th>Từ :</th>
                  <td>{{ lg.start_time }}</td>
                  <th>Đến :</th>
                  <td>{{ lg.end_time }}</td>
                  <th>Loại điều kiện :</th>
                  <td>{{ convertLoginSqCond(lg.key) }}</td>
                  <th v-if="showTotal(lg)">Số lần :</th>
                  <td v-if="showTotal(lg)">{{ lg.op }} {{ lg.total }}</td>
                </div>
              </tr>
            </tbody>
          </table>
        </b-card-text>
      </b-card>
      <br />
    </div>
    <!--show theo transaction-->
    <div
      class="div-user"
      v-if="show.is_transaction && this.trans_props != null"
    >
      <b-card>
        <b-card-text>
          <h5>Theo giao dịch</h5>
          <table class="table">
            <thead></thead>
            <tbody>
              <tr v-for="(trans, key) in this.trans_props" :key="key">
                <div class="OR" v-if="Array.isArray(trans)">
                  <tr v-for="(trans_v2, key) in trans" :key="key">
                    <div
                      class="detail-merchant"
                      v-if="trans_v2.key === 'MERCHANT'"
                    >
                      <th scope="row">{{ trans_v2.condition }}</th>
                      <td>
                        <b>Từ ngày: </b><br />
                        {{ trans_v2.start_time }}
                      </td>
                      <td>
                        <b>Đến ngày: </b><br />
                        {{ trans_v2.end_time }}
                      </td>
                      <td>
                        <b>Merchant: </b><br />
                        {{ trans_v2.merchant_code }}
                      </td>
                      <td>
                        <b>Số giao dịch đã thanh toán: </b>
                        {{ trans_v2.total_trans.op }}
                        {{ trans_v2.total_trans.value }}
                      </td>
                      <td>
                        <b>Tổng giá trị đã thanh toán: </b>
                        {{ trans_v2.trans_amount.op }}
                        {{ trans_v2.trans_amount.value }}
                      </td>
                    </div>
                    <!--service-->
                    <div
                      class="detail-merchant"
                      v-if="trans_v2.key === 'SERVICE'"
                    >
                      <th scope="row">{{ trans_v2.condition }}</th>
                      <td>
                        <b>Từ ngày: </b><br />
                        {{ trans_v2.start_time }}
                      </td>
                      <td>
                        <b>Đến ngày: </b><br />
                        {{ trans_v2.end_time }}
                      </td>
                      <td>
                        <b>Loại DV: </b><br />
                        {{
                          trans_v2.service_type != null
                            ? trans_v2.service_type
                            : "Tất cả"
                        }}
                      </td>
                      <td>
                        <b>Dịch vụ: </b><br />
                        {{
                          trans_v2.service_code != null
                            ? trans_v2.service_code
                            : "Tất cả"
                        }}
                      </td>
                      <td>
                        <b>Số giao dịch đã thanh toán: </b>
                        {{ trans_v2.total_trans.op }}
                        {{ trans_v2.total_trans.value }}
                      </td>
                      <td>
                        <b>Tổng giá trị đã thanh toán: </b>
                        {{ trans_v2.trans_amount.op }}
                        {{ trans_v2.trans_amount.value }}
                      </td>
                    </div>
                  </tr>
                </div>
                <div v-else>
                  <div class="detail-merchant" v-if="trans.key === 'MERCHANT'">
                    <th scope="row">{{ trans.condition }}</th>
                    <td>
                      <b>Từ ngày: </b><br />
                      {{ trans.start_time }}
                    </td>
                    <td>
                      <b>Đến ngày: </b><br />
                      {{ trans.end_time }}
                    </td>
                    <td>
                      <b>Merchant: </b><br />
                      {{ trans.merchant_code }}
                    </td>
                    <td>
                      <b>Số giao dịch đã thanh toán: </b>
                      {{ trans.total_trans.op }} {{ trans.total_trans.value }}
                    </td>
                    <td>
                      <b>Tổng giá trị đã thanh toán: </b>
                      {{ trans.trans_amount.op }} {{ trans.trans_amount.value }}
                    </td>
                  </div>
                  <!--service-->
                  <div class="detail-merchant" v-if="trans.key === 'SERVICE'">
                    <th scope="row">{{ trans.condition }}</th>
                    <td>
                      <b>Từ ngày: </b><br />
                      {{ trans.start_time }}
                    </td>
                    <td>
                      <b>Đến ngày: </b><br />
                      {{ trans.end_time }}
                    </td>
                    <td>
                      <b>Loại DV: </b><br />
                      {{
                        trans.service_type != null
                          ? trans.service_type
                          : "Tất cả"
                      }}
                    </td>
                    <td>
                      <b>Dịch vụ: </b><br />
                      {{
                        trans.service_code != null
                          ? trans.service_code
                          : "Tất cả"
                      }}
                    </td>
                    <td>
                      <b>Số giao dịch đã thanh toán: </b>
                      {{ trans.total_trans.op }} {{ trans.total_trans.value }}
                    </td>
                    <td>
                      <b>Tổng giá trị đã thanh toán: </b>
                      {{ trans.trans_amount.op }} {{ trans.trans_amount.value }}
                    </td>
                  </div>
                </div>
              </tr>
            </tbody>
          </table>
        </b-card-text>
      </b-card>
    </div>
    <br />
    <b-button variant="primary" style="float: right" @click="showFormUser()"
      >Chỉnh sửa Segment user
    </b-button>
    <b-modal
      id="changeUserSegment"
      size="lg"
      title="Chỉnh sửa Segment user"
      hide-footer
      ref="changeUserSegment"
    >
      <FormChangeUserSegment :dataDetail="detail"></FormChangeUserSegment>
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import Multiselect from "vue-multiselect";
import FormChangeUserSegment from "./FormChangeUserSegment.vue";
import defineConfig from "@/core/config/define.config";
import RepositoryFactory from "@/core/repositories/repositoryFactory";

const CmsRepository = RepositoryFactory.get("cms");
Vue.component("multiselect", Multiselect);

export default {
  props: ["dataDetail"],
  components: {
    FormChangeUserSegment,
  },
  data() {
    return {
      STATUS_FALSE: defineConfig.STATUS_CONFIG.STATUS_FALSE,
      STATUS_SUCCESS: defineConfig.STATUS_CONFIG.STATUS_SUCCESS,
      STATUS_PENDING: defineConfig.STATUS_CONFIG.STATUS_PENDING,
      STATUS_PROCESS: defineConfig.STATUS_CONFIG.STATUS_PROCESS,
      detail: this.dataDetail,
      user_props: null,
      bank_props: null,
      trans_props: null,
      loginsequent: null,
      segmentDiffTitle: [],
      show: {
        is_user: false,
        is_bank: false,
        is_transaction: false,
        isLoginSq: false,
      },
    };
  },
  methods: {
    convertData() {
      this.show.is_user = false;
      this.show.is_bank = false;
      this.show.is_transaction = false;
      this.show.isLoginSq = false;
      //user
      if (this.detail.user_props != null) {
        this.show.is_user = true;
        this.user_props = JSON.parse(this.detail.user_props);
      }
      // bank
      if (this.detail.bank_props != null) {
        this.show.is_bank = true;
        this.bank_props = JSON.parse(this.detail.bank_props);
      }
      // transaction
      if (this.detail.trans_props != null) {
        this.show.is_transaction = true;
        this.trans_props = JSON.parse(this.detail.trans_props);
      }

      // transaction
      if (this.detail.loginsequent != null) {
        this.show.isLoginSq = true;
        this.loginsequent = JSON.parse(this.detail.loginsequent);
      }
    },

    convertLoginSqCond(cond) {
      switch (cond) {
        case "total_by_time":
          return "Số lần đăng nhập";
        case "total_by_date":
          return "Số ngày đăng nhập";
        case "sequent_by_date":
          return "Đăng nhập liên tiếp theo ngày";
        case "sequent_by_month":
          return "Đăng nhập liên tiếp theo tháng";
      }
    },

    showTotal(cond) {
      if (cond.key === "total_by_time" || cond.key === "total_by_date") {
        return true;
      }
      return false;
    },

    showFormUser() {
      this.$refs["changeUserSegment"].show();
    },
    searchSegment() {
      if (
        this.detail.segment_diffs == null ||
        this.detail.segment_diffs?.length < 1
      ) {
        this.segmentDiffTitle = "";
        return;
      }
      let params = {
        ids: this.detail.segment_diffs,
      };
      CmsRepository.listAllSegment(params)
        .then((response) => {
          if (response.data.error_code) {
            alert(response.data.message);
          } else {
            this.segmentDiffTitle = response.data.data.data.map((segment) => {
              return segment.id + " - " + segment.title;
            });
          }
        })
        .catch(() => {
          alert("Có lỗi xảy ra");
        });
    },
  },
  created() {
    this.convertData();
    if (
      this.detail.segment_diffs != null ||
      this.detail.segment_diffs?.length > 0
    ) {
      this.searchSegment();
    }
  },
};
</script>
<style>
.detail-merchant td {
  border: none;
}

.detail-merchant th {
  border: none;
}
</style>
