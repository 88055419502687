<template>
  <div>
    <b>{{ condition }}</b>
    <b-card bg-variant="light">
      <b-row v-if="loginsq.key === 'sequent_by_month'">
        <b-col>
          <b-form-group label="Từ (*)">
            <b-form-input
              :id="`type-date`"
              :type="`month`"
              v-model="loginsq.start_time"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Đến (*)">
            <b-form-input
              :id="`type-date`"
              :type="`month`"
              v-model="loginsq.end_time"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="loginsq.key !== 'sequent_by_month'">
        <b-col>
          <b-form-group label="Từ (*)">
            <b-form-input
              :id="`type-date`"
              :type="`date`"
              v-model="loginsq.start_time"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Đến (*)">
            <b-form-input
              :id="`type-date`"
              :type="`date`"
              v-model="loginsq.end_time"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Loại điều kiện (*)">
            <b-form-select v-model="loginsq.key" :options="option" required>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col
          v-if="
            loginsq.key !== 'sequent_by_date' &&
            loginsq.key !== 'sequent_by_month'
          "
        >
          <b-form-group label="Số lần (*)">
            <b-form-select
              style="width: 30%; display: inline-block"
              v-model="loginsq.op"
              :options="operatorOption"
              required
            ></b-form-select>
            <b-form-input
              style="width: 70%; display: inline-block"
              v-model="loginsq.total"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import Vue from "vue";
import Multiselect from "vue-multiselect";
import Common from "@/core/mixins/common";
Vue.component("multiselect", Multiselect);
export default {
  name: "FormLoginSequent",
  props: ["condition", "indexKey", "dataEdit", "isEdit"],
  mixins: [Common],
  data() {
    return {
      loginsq: {
        key: "total_by_time",
        start_time: this.getYesterday(),
        end_time: this.getCurrentDay(),
        op: ">",
        total: 0,
        condition: this.condition,
        indexKey: this.indexKey,
      },
      option: {
        total_by_time: "Số lần đăng nhập",
        total_by_date: "Số ngày đăng nhập",
        sequent_by_date: "Đăng nhập liên tiếp theo ngày",
        sequent_by_month: "Đăng nhập liên tiếp theo tháng",
      },
      operatorOption: [
        { value: ">", text: ">" },
        { value: "<", text: "<" },
        { value: "=", text: "=" },
      ],
    };
  },
  methods: {},
  created() {
    if (this.isEdit) {
      this.loginsq = this.dataEdit;
    }
  },
};
</script>

<style scoped></style>
