<template>
  <div class="div-changeUser">
    <b-form class="col-12">
      <b-form-group label="Tên segment user (*):">
        <b-form-input
          v-model="title"
          placeholder="Nhập tên segment"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group>
        <b-row>
          <b-col>Số lượng user (*):</b-col>
          <b-col>
            <b-button
              size="sm"
              variant="outline-primary"
              @click="changeUser(100)"
              >100%</b-button
            >
            <b-button
              size="sm"
              variant="outline-primary"
              @click="changeUser(75)"
              >75%</b-button
            >
            <b-button
              size="sm"
              variant="outline-primary"
              @click="changeUser(50)"
              >50%</b-button
            >
            <b-button
              size="sm"
              variant="outline-primary"
              @click="changeUser(25)"
              >25%</b-button
            >
          </b-col>
        </b-row>
        <br />
        <b-form-input
          placeholder="Số lượng user"
          v-model="filterTotal"
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Sắp xếp user theo (*):">
        <b-form-select
          v-model="userSort.value"
          :options="userSort.option"
        ></b-form-select>
      </b-form-group>
      <b-button variant="primary" style="float: right" @click="updateUser()"
        >Lưu</b-button
      >
    </b-form>
  </div>
</template>

<script>
import Vue from "vue";
import Multiselect from "vue-multiselect";
import RepositoryFactory from "@/core/repositories/repositoryFactory";

const CmsRepository = RepositoryFactory.get("cms");

Vue.component("multiselect", Multiselect);

export default {
  props: ["dataDetail"],
  data() {
    return {
      detail: this.dataDetail,
      title: this.dataDetail.title,
      userSort: {
        value: "ASC",
        option: [
          { value: "ASC", text: "User ID giảm dần" },
          { value: "DESC", text: "User ID tăng dần" },
        ],
      },
      filterTotal: this.dataDetail.segment_users_count,
      userTotal: this.dataDetail.segment_users_count,
    };
  },
  methods: {
    changeUser(item) {
      let userNumber = (this.userTotal * item) / 100;
      this.filterTotal = userNumber.toFixed();
    },
    updateUser() {
      this.$bus.$emit("show-loading", true);
      let params = {
        segment_id: this.detail.id,
        title: this.title,
        user_id_sort: this.userSort.value,
        filter_total: this.filterTotal,
      };
      CmsRepository.updateUserSegment(params)
        .then((response) => {
          this.$bus.$emit("show-loading", false);
          if (response.data.error_code) {
            alert(response.data.message);
          } else {
            location.reload();
          }
        })
        .catch(() => {
          alert("Có lỗi xảy ra");
        });
    },
  },
  created() {},
};
</script>
