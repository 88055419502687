<template>
  <div>
    <b>{{ condition }}</b>
    <b-card bg-variant="light">
      <hr />
      <b-row>
        <b-col>
          <b-form-group label="Từ ngày (*)">
            <b-form-input
              :id="`type-date`"
              :type="`date`"
              v-model="service.start_time"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Đến ngày (*)">
            <b-form-input
              :id="`type-date`"
              :type="`date`"
              v-model="service.end_time"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Loại dịch vụ (*)">
            <b-form-select v-model="service.service_type" :options="option">
              <b-form-select-option :value="null">Tất cả</b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Danh sách dịch vụ">
            <b-form-select
              v-model="service.service_code"
              :options="serviceCodetOption"
            >
              <b-form-select-option :value="null">Tất cả</b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Số gd đã thanh toán (*)">
            <b-form-select
              style="width: 30%; display: inline-block"
              v-model="service.total_trans.op"
              :options="operatorOption"
              required
            ></b-form-select>
            <b-form-input
              style="width: 70%; display: inline-block"
              v-model="service.total_trans.value"
              placeholder="Số giao dịch đã thanh toán"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Tổng giá trị gd đã thanh toán (*)">
            <b-form-select
              style="width: 30%; display: inline-block"
              v-model="service.trans_amount.op"
              :options="operatorOption"
              required
            ></b-form-select>
            <b-form-input
              style="width: 70%; display: inline-block"
              v-model="service.trans_amount.value"
              placeholder="Tổng giá trị thanh toán"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import Vue from "vue";
import Multiselect from "vue-multiselect";
import Common from "@/core/mixins/common";

Vue.component("multiselect", Multiselect);
import RepositoryFactory from "@/core/repositories/repositoryFactory";

const CmsRepository = RepositoryFactory.get("cms");
export default {
  props: ["condition", "indexKey", "dataEdit", "isEdit"],
  mixins: [Common],
  data() {
    return {
      service: {
        key: "SERVICE",
        start_time: this.getYesterday(),
        end_time: this.getCurrentDay(),
        service_type: null,
        service_code: null,
        trans_amount: {
          op: ">",
          value: 0,
        },
        total_trans: {
          op: ">",
          value: 0,
        },
        condition: this.condition,
        indexKey: this.indexKey,
      },
      option: [],
      serviceCodetOption: [],
      operatorOption: [
        { value: ">", text: ">" },
        { value: "<", text: "<" },
        { value: "=", text: "=" },
      ],
    };
  },
  methods: {
    getTypeService() {
      CmsRepository.listService()
        .then((response) => {
          if (response.data.error_code) {
            alert(response.data.message);
          } else {
            this.option = response.data.data;
          }
        })
        .catch(() => {
          alert("Có lỗi xảy ra");
        });
    },
    getServiceByType(type) {
      let params = {
        type: type,
      };
      CmsRepository.listServiceByType(params)
        .then((response) => {
          if (response.data.error_code) {
            alert(response.data.message);
          } else {
            this.serviceCodetOption = response.data.data;
          }
        })
        .catch(() => {
          alert("Có lỗi xảy ra");
        });
    },
  },
  watch: {
    "service.service_type"() {
      this.service.service_code = null;
      this.serviceCodetOption = [];
      if (this.service.service_type != null) {
        this.getServiceByType(this.service.service_type);
      }
    },
  },
  created() {
    if (this.isEdit) {
      this.service = this.dataEdit;
    }
    this.getTypeService();
  },
};
</script>
<style lang="scss" scoped>
button.btn.btn-danger {
  margin-left: 5px;
}
</style>
