<template>
  <div>
    <b-card-group deck>
      <notifications group="alert" position="top center" />
      <b-card header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h6 class="mb-0">Danh sách Segment</h6>
          <b-button
            class="float-right"
            variant="outline-primary"
            @click="addNewForm()"
            >Tạo mới
          </b-button>
          <b-button
            class="float-right mr-3"
            variant="outline-success"
            @click="addNewManual()"
            >Tạo mới thủ công
          </b-button>
        </template>

        <b-row>
          <b-col>
            <b-form-group label="Tiêu đề">
              <b-form-input v-model="filter.search"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Trạng thái">
              <b-form-select
                :options="status"
                v-model="filter.status"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Thời gian bắt đầu(*)">
              <b-form-input
                :id="`type-date`"
                :type="`date`"
                v-model="filter.date_from"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Thời gian kết thúc(*)">
              <b-form-input
                :id="`type-date`"
                :type="`date`"
                v-model="filter.date_to"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col class="pt-4">
            <b-button class="mt-5" variant="outline-primary" @click="listAll()"
              >Tìm kiếm
            </b-button>
          </b-col>
        </b-row>

        <b-table
          :items="items"
          :fields="fields"
          ref="table"
          striped
          hover
          responsive
          caption-top
        >
          <template #cell(segment_users_count)="data">
            {{
              data.item.segment_users_count > 0
                ? numberFormat(data.item.segment_users_count)
                : data.item.segment_users_count
            }}
          </template>

          <template #cell(created_at)="data">
            {{ new Date(data.item.created_at).toLocaleString() }}
          </template>
          <template #cell(status)="data">
            <b-badge v-if="data.item.status === STATUS_FALSE" variant="danger"
              >False
            </b-badge>
            <b-badge
              v-if="data.item.status === STATUS_PENDING"
              variant="secondary"
              >Pending
            </b-badge>
            <b-badge
              v-if="data.item.status === STATUS_PROCESS"
              variant="warning"
              >Process
            </b-badge>
            <b-badge
              v-if="data.item.status === STATUS_SUCCESS"
              variant="success"
              >Success
            </b-badge>
          </template>
          <template #cell(options)="data">
            <button @click="showUpdate(data.item)">
              <i class="fa fa-search-plus bluex" aria-hidden="true"></i>
            </button>
            &nbsp;
            <button variant="outline-danger" @click="deleteItem(data.item.id)">
              <i class="fa fa-trash red" aria-hidden="true"></i>
            </button>
            <button
              class="ml-3"
              variant="outline-danger"
              @click="cloneItem(data.item)"
            >
              <i class="fa fa-clone" aria-hidden="true"></i>
            </button>
          </template>
        </b-table>
        <div class="mt-3">
          <b-pagination-nav
            v-model="currentPage"
            :link-gen="createLinkPage"
            :number-of-pages="paginate.totalPage"
            use-router
            first-number
            last-number
            align="right"
          ></b-pagination-nav>
        </div>
      </b-card>
    </b-card-group>
    <b-modal
      id="myModal"
      size="xl"
      :title="titleCreate"
      hide-footer
      ref="myModal"
      class="modal-xl"
      data-toggle="myModal"
      :no-close-on-backdrop="true"
    >
      <b-form class="col-12">
        <b-row>
          <b-col>
            <b-form-group label="Tên Segment (*)">
              <b-form-input
                v-model="formInput.title"
                placeholder="Nhập tên segment"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Mô tả (*)">
              <b-form-textarea
                id="textarea"
                v-model="formInput.description"
                placeholder="Mô tả"
                rows="2"
                required
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-form-group label="Loại trừ Segment đã tạo">
              <multiselect
                v-model="segmentDiff.value"
                :options="segmentDiff.option"
                :multiple="true"
                label="title"
                track-by="id"
                @search-change="asyncFind"
              ></multiselect>
            </b-form-group>
          </b-col>
          <b-col></b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col cols="6">
            <b-form-group label="Các tiêu chí phân loại">
              <multiselect
                v-model="segment.value"
                :options="segment.option"
                :multiple="true"
                label="text"
                track-by="value"
              ></multiselect>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Nhấn để thêm tiêu chí">
              <b-button @click="addStandard()">Thêm tiêu chí</b-button>
            </b-form-group>
          </b-col>
          <b-col></b-col>
        </b-row>
        <hr />
        <b-row class="div-customer" v-if="this.is_Customer">
          <b-col>
            <FormCustomer
              ref="formCustomer"
              :customerDad="customer"
              :dateCheck="date_check"
              :kycCheck="kyc_check"
            ></FormCustomer>
          </b-col>
        </b-row>
        <div class="div-bank" v-if="this.is_Bank">
          <b-row class="div-form-bank">
            <b-col>
              <b-row>
                <b-col>
                  <br />
                  <hr />
                  <legend>Theo ngân hàng</legend>
                </b-col>
                <b-col>
                  <b-row>
                    <b-col
                      ><p>Thêm tiêu chí</p>
                      <b-form-select
                        v-model="type.bank.condition.value"
                        :options="type.option"
                      ></b-form-select>
                    </b-col>
                    <b-col
                      ><p>Tùy chọn</p>
                      <b-button @click="addForm('bank')">Thêm</b-button>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
            <hr />
          </b-row>
          <b-row>
            <b-col>
              <div
                class="form-option"
                v-for="(itemType, key) in valueFormBank"
                :key="key"
              >
                <button class="floatRight" @click="bankClose(key)">
                  <i class="fa fa-times-circle" aria-hidden="true"></i>
                </button>
                <FormBank
                  ref="formBank"
                  :editBank="editBank"
                  :bankProp="itemType"
                  :condition="itemType.condition"
                  :indexKey="key"
                  :class="{ marginLeft: itemType.condition === 'OR' }"
                ></FormBank>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="div-transaction" v-if="this.is_Transaction">
          <b-row class="div-transaction">
            <b-col>
              <br />
              <hr />
              <legend>Theo giao dịch</legend>
            </b-col>
            <b-col>
              <b-row>
                <b-col>
                  <p>Chọn loại</p>
                  <b-form-select
                    v-model="type.transaction.value"
                    :options="type.transaction.option"
                  ></b-form-select>
                </b-col>
                <b-col
                  ><p>Thêm tiêu chí</p>
                  <b-form-select
                    v-model="type.transaction.condition.value"
                    :options="type.option"
                  ></b-form-select>
                </b-col>
                <b-col>
                  <p>Tùy chọn</p>
                  <b-button @click="addForm('transaction')">Thêm</b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <hr />
          <div
            class="form-option"
            v-for="(itemType, key) in valueFormMerchant"
            :key="key"
          >
            <button class="floatRight" @click="TransactionClose(key)">
              <i class="fa fa-times-circle" aria-hidden="true"></i>
            </button>
            <FormMerchant
              ref="formMerchant"
              :isEdit="editTransaction"
              :dataEdit="itemType"
              v-if="itemType.type === 'MERCHANT' || itemType.key === 'MERCHANT'"
              :condition="itemType.condition"
              :indexKey="key"
              :class="{ marginLeft: itemType.condition === 'OR' }"
            ></FormMerchant>
            <FormService
              ref="formService"
              :isEdit="editTransaction"
              :dataEdit="itemType"
              :condition="itemType.condition"
              :indexKey="key"
              v-if="itemType.type === 'SERVICE' || itemType.key === 'SERVICE'"
              :class="{ marginLeft: itemType.condition === 'OR' }"
            ></FormService>
          </div>
        </div>
        <div class="div-loginsequent" v-if="this.isLoginSequent">
          <b-row class="div-loginsequent">
            <b-col>
              <br />
              <hr />
              <legend>Theo tần suất đăng nhập</legend>
            </b-col>
            <b-col>
              <b-row>
                <b-col
                  ><p>Thêm tiêu chí</p>
                  <b-form-select
                    v-model="type.loginsequent.condition.value"
                    :options="type.option"
                  ></b-form-select>
                </b-col>
                <b-col>
                  <p>Tùy chọn</p>
                  <b-button @click="addForm('loginsequent')">Thêm</b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <hr />
          <div
            class="form-option"
            v-for="(itemType, key) in valueFormLoginSq"
            :key="key"
          >
            <button class="floatRight" @click="LoginSqClose(key)">
              <i class="fa fa-times-circle" aria-hidden="true"></i>
            </button>
            <FormLoginSequent
              ref="formLoginSequent"
              :dataEdit="itemType"
              :isEdit="editLoginSequent"
              :condition="itemType.condition"
              :indexKey="key"
              :class="{ marginLeft: itemType.condition === 'OR' }"
            ></FormLoginSequent>
          </div>
        </div>
        <div class="div-user-group" v-if="this.isGroups">
          <b-row class="div-form-user-group">
            <b-col>
              <b-row>
                <b-col>
                  <br />
                  <hr />
                  <legend>Theo nhóm khách hàng</legend>
                </b-col>
                <b-col>
                  <b-row>
                    <b-col cols="12">
                      <b-form-group label="Chọn các nhóm (điều kiện OR)">
                        <multiselect
                          v-model="type.groups.condition"
                          :options="list_group"
                          :multiple="true"
                          label="text"
                          track-by="value"
                        ></multiselect>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
            <hr />
          </b-row>
          <!--          <b-row>-->
          <!--            <b-col>-->
          <!--              <div-->
          <!--                class="form-option"-->
          <!--                v-for="(itemType, key) in valueFormBank"-->
          <!--                :key="key"-->
          <!--              >-->
          <!--                <button class="floatRight" @click="bankClose(key)">-->
          <!--                  <i class="fa fa-times-circle" aria-hidden="true"></i>-->
          <!--                </button>-->
          <!--                <FormBank-->
          <!--                  ref="formBank"-->
          <!--                  :condition="itemType.condition"-->
          <!--                  :indexKey="key"-->
          <!--                  :class="{ marginLeft: itemType.condition === 'OR' }"-->
          <!--                ></FormBank>-->
          <!--              </div>-->
          <!--            </b-col>-->
          <!--          </b-row>-->
        </div>
        <br />
        <b-row style="float: right">
          <button
            type="button"
            class="btn btn-secondary"
            data-dismiss="myModal"
            @click="$bvModal.hide('myModal')"
          >
            Hủy</button
          >&nbsp;
          <b-button type="button" variant="primary" @click="store()"
            >Tạo mới
          </b-button>
        </b-row>
      </b-form>
    </b-modal>

    <b-modal
      id="customerCreate"
      size="xl"
      title="Tạo mới Segment thủ công"
      hide-footer
      ref="customerCreate"
      class="modal-xl"
      data-toggle="customerCreate"
      :no-close-on-backdrop="true"
    >
      <b-form class="col-12">
        <b-row>
          <b-col>
            <b-form-group label="Tên Segment (*)">
              <b-form-input
                v-model="formInput.title"
                placeholder="Nhập tên segment"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Mô tả (*)">
              <b-form-textarea
                id="textarea"
                v-model="formInput.description"
                placeholder="Mô tả"
                rows="2"
                required
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-form-group label="SĐT">
              <b-form-file
                v-model="file"
                :state="stage"
                placeholder="Chọn hoặc kéo file vào đây.(xls, xlsx)"
                drop-placeholder="Kéo file vào đây..."
              ></b-form-file>
            </b-form-group>

            <p>
              Download file mẫu
              <a
                href="https://cdn.9pay.vn/templates/import_users.xlsx"
                target="_blank"
                >TẠI ĐÂY</a
              >
            </p>
          </b-col>
          <b-col></b-col>
        </b-row>
        <br />
        <b-row style="float: right">
          <button
            type="button"
            class="btn btn-secondary"
            data-dismiss="myModal"
            @click="$bvModal.hide('customerCreate')"
          >
            Hủy</button
          >&nbsp;
          <b-button type="button" variant="primary" @click="storeByFile()"
            >Tạo mới
          </b-button>
        </b-row>
      </b-form>
    </b-modal>
    <!--Chi tiết segment-->
    <b-modal
      id="detailSegment"
      size="xl"
      title="Chi tiết segment"
      hide-footer
      ref="detailSegment"
    >
      <FormDetail :dataDetail="detail"></FormDetail>
    </b-modal>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.marginLeft {
  margin-left: 30px;
}
</style>

<script>
import Vue from "vue";
import Multiselect from "vue-multiselect";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import FormMerchant from "./FormMerchant.vue";
import FormService from "./FormService.vue";
import FormCustomer from "./FormCustomerv2.vue";
import FormBank from "./FormBank.vue";
import FormDetail from "./FormDetail.vue";
import Notifications from "vue-notification";

import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";
import defineConfig from "@/core/config/define.config";
import FormLoginSequent from "@/view/pages/tools/segment/FormLoginSequent";

const CmsRepository = RepositoryFactory.get("cms");
Vue.component("multiselect", Multiselect);
Vue.use(Notifications);

export default {
  components: {
    FormLoginSequent,
    FormMerchant,
    FormService,
    FormCustomer,
    FormBank,
    FormDetail,
  },
  mixins: [Common],
  data() {
    return {
      titleCreate: "Tạo mới Segment",
      file: null,
      stage: true,
      extensions: [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
      ],
      STATUS_FALSE: defineConfig.STATUS_CONFIG.STATUS_FALSE,
      STATUS_SUCCESS: defineConfig.STATUS_CONFIG.STATUS_SUCCESS,
      STATUS_PENDING: defineConfig.STATUS_CONFIG.STATUS_PENDING,
      STATUS_PROCESS: defineConfig.STATUS_CONFIG.STATUS_PROCESS,
      status: [
        { text: "Tất cả", value: "" },
        { text: "False", value: defineConfig.STATUS_CONFIG.STATUS_FALSE },
        { text: "Success", value: defineConfig.STATUS_CONFIG.STATUS_SUCCESS },
        { text: "Pending", value: defineConfig.STATUS_CONFIG.STATUS_PENDING },
        { text: "Process", value: defineConfig.STATUS_CONFIG.STATUS_PROCESS },
      ],
      filter: {
        status: null,
        search: null,
        date_from: this.getFirstDayOfMonth(),
        date_to: this.getCurrentDay(),
      },
      isLoading: false,
      segment: {
        option: [
          { value: "CUSTOMER", text: "Thuộc tính khách hàng" },
          { value: "TRANSACTION", text: "Theo giao dịch" },
          { value: "LOGINSEQUENT", text: "Tần suất đăng nhập" },
          { value: "BANK", text: "Liên kết ngân hàng" },
          { value: "GROUPS", text: "Nhóm khách hàng" },
        ],
        value: [],
      },
      segmentDiff: {
        option: [],
        value: [],
        // value: null,
      },
      fields: [
        { id: "Segment Id" },
        { title: "Tiêu đề" },
        { status: "Trạng thái" },
        { created_by: "Người tạo" },
        { created_at: "Ngày tạo" },
        { segment_users_count: "Số lượng user" },
        { options: "Tùy chọn" },
      ],
      items: [],
      list_group: [],
      formInput: {
        id: null,
        title: null,
        description: null,
        status: {
          value: 0,
          option: [
            { value: 0, text: "không hoạt động" },
            { value: 1, text: "Hoạt động" },
          ],
        },
      },
      type: {
        transaction: {
          value: "MERCHANT",
          option: [
            { value: "MERCHANT", text: "Merchant" },
            { value: "SERVICE", text: "Dịch vụ" },
          ],
          condition: {
            value: "AND",
          },
        },
        bank: {
          condition: {
            value: "AND",
          },
        },
        loginsequent: {
          condition: {
            value: "AND",
          },
        },
        groups: {
          condition: [],
        },
        option: [
          { value: "AND", text: "AND" },
          { value: "OR", text: "OR" },
        ],
      },
      merchant: {
        date: {
          from: null,
          to: null,
        },
        value: null,
        option: [],
        number: {
          operator: ">",
          payment: 0,
        },
        total: {
          operator: ">",
          payment: 0,
        },
      },
      service: {
        date: {
          from: null,
          to: null,
        },
        value: null,
        option: [],
        number: {
          operator: ">",
          payment: 0,
        },
        total: {
          operator: ">",
          payment: 0,
        },
        product: {
          value: 0,
          option: [],
        },
      },
      operatorOption: [
        { value: ">", text: ">" },
        { value: "<", text: "<" },
        { value: "=", text: "=" },
      ],
      types: [],
      valueFormMerchant: [],
      valueFormBank: [],
      valueFormLoginSq: [],
      is_Customer: false,
      is_Transaction: false,
      isLoginSequent: false,
      isGroups: false,
      is_Bank: false,
      customer: {
        register_time: {
          start_time: this.getYesterday(),
          end_time: this.getCurrentDay(),
        },
        kyc: false,
      },
      date_check: true,
      kyc_check: true,
      editBank: false,
      editTransaction: false,
      editLoginSequent: false,
      detail: {
        id: null,
        title: null,
        status: null,
        created_by: null,
        segment_diff: null,
        segment_diffs: null,
      },
      dateToDay: this.getCurrentDay(),
      currentPage: 1,
      paginate: {
        limit: 25,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Segment", route: "tools" },
    ]);
  },
  methods: {
    formatInput() {
      this.formInput.id = null;
      this.formInput.title = null;
      this.formInput.description = null;
      this.formInput.status.value = 0;
      this.segment.value = [];
      this.valueFormMerchant = [];
      this.valueFormBank = [];

      this.is_Customer = false;
      this.is_Transaction = false;
      this.is_Bank = false;

      this.segmentDiff.value = [];
      this.type.groups.condition = [];
      this.valueFormLoginSq = [];
      this.customer = {
        register_time: {
          start_time: this.getYesterday(),
          end_time: this.getCurrentDay(),
        },
        kyc: false,
      };

      this.date_check = true;
      this.kyc_check = true;
      this.editBank = false;
      this.editTransaction = false;
      this.editLoginSequent = false;

      this.addStandard();
    },
    addNewForm() {
      this.titleCreate = "Tạo mới Segment";
      this.formatInput();
      this.$refs["myModal"].show();
    },
    addNewManual() {
      this.formatInput();
      this.$refs["customerCreate"].show();
    },
    checkInputBank(params) {
      if (params.value === null) {
        return false;
      }
      if (
        (params.register_time.start_time > this.getCurrentDay() ||
          params.register_time.end_time > this.getCurrentDay()) &&
        params.register_time.end_time != null
      ) {
        this.notifyAlert("warn", "Khoảng thời gian phải nhỏ hơn ngày hiện tại");
        return false;
      }
      if (
        params.register_time.start_time > params.register_time.end_time &&
        params.register_time.end_time != null
      ) {
        this.notifyAlert("warn", "Ngày bắt đầu phải nhỏ hơn ngày kết thúc");
        return false;
      }
      return true;
    },

    checkInputLoginSq(params) {
      if (
        params.start_time > this.getCurrentDay() ||
        params.end_time > this.getCurrentDay()
      ) {
        this.notifyAlert("warn", "Khoảng thời gian phải nhỏ hơn ngày hiện tại");
        return false;
      }

      if (params.start_time > params.end_time) {
        this.notifyAlert("warn", "Ngày bắt đầu phải nhỏ hơn ngày kết thúc");
        return false;
      }
      if (params.condition === "OR" && params.indexKey == 0) {
        this.notifyAlert("warn", "Điều kiện đầu tiên phải là AND");
        return false;
      }

      if (
        (params.indexKey == 0 &&
          (params.key == "total_by_time" || params.key == "total_by_date") &&
          params.total == 0 &&
          params.op === "=") ||
        (params.condition === "OR" &&
          (params.key == "total_by_time" || params.key == "total_by_date") &&
          params.total == 0 &&
          params.op === "=")
      ) {
        this.notifyAlert(
          "warn",
          "Không được chọn số lần login = 0 tại điều kiện đầu tiên. Không được chọn số lần login = 0 trong điều kiện OR."
        );
        return false;
      }

      return true;
    },

    checkInputMerchant(params) {
      if (params.total_trans.value === null) {
        return false;
      }
      if (params.trans_amount.value === null) {
        return false;
      }
      if (
        params.start_time > this.getCurrentDay() ||
        params.end_time > this.getCurrentDay()
      ) {
        this.notifyAlert("warn", "Khoảng thời gian phải nhỏ hơn ngày hiện tại");
        return false;
      }
      if (params.start_time > params.end_time) {
        this.notifyAlert("warn", "Ngày bắt đầu phải nhỏ hơn ngày kết thúc");
        return false;
      }
      if (params.condition === "OR" && params.indexKey == 0) {
        this.notifyAlert("warn", "Điều kiện đầu tiên phải là AND");
        return false;
      }
      if (
        params.indexKey == 0 &&
        ((params.total_trans.value == 0 && params.total_trans.op === "=") ||
          (params.trans_amount.value == 0 && params.total_trans.op === "="))
      ) {
        this.notifyAlert(
          "warn",
          "Không được chọn số giao dịch hoặc tổng giá trị = 0 tại điều kiện AND đầu tiên. Không được chọn số giao dịch hoặc tổng giá trị = 0 trong điều kiện OR."
        );
        return false;
      }
      if (
        params.condition === "OR" &&
        ((params.total_trans.value == 0 && params.total_trans.op === "=") ||
          (params.trans_amount.value == 0 && params.total_trans.op === "="))
      ) {
        this.notifyAlert(
          "warn",
          "Không được chọn số giao dịch hoặc tổng giá trị = 0 tại điều kiện AND đầu tiên. Không được chọn số giao dịch hoặc tổng giá trị = 0 trong điều kiện OR."
        );
        return false;
      }

      return true;
    },
    checkInputService(params) {
      if (params.total_trans.value === null) {
        return false;
      }
      if (params.trans_amount.value === null) {
        return false;
      }
      if (
        params.start_time > this.getCurrentDay() ||
        params.end_time > this.getCurrentDay()
      ) {
        this.notifyAlert("warn", "Khoảng thời gian phải nhỏ hơn ngày hiện tại");
        return false;
      }
      if (params.start_time > params.end_time) {
        this.notifyAlert("warn", "Ngày bắt đầu phải nhỏ hơn ngày kết thúc");
        return false;
      }

      if (params.condition === "OR" && params.indexKey == 0) {
        this.notifyAlert("warn", "Điều kiện đầu tiên phải là AND");
        return false;
      }
      if (
        params.indexKey == 0 &&
        ((params.total_trans.value == 0 && params.total_trans.op === "=") ||
          (params.trans_amount.value == 0 && params.trans_amount.op === "="))
      ) {
        this.notifyAlert(
          "warn",
          "Không được chọn số giao dịch hoặc tổng giá trị = 0 tại điều kiện AND đầu tiên. Không được chọn số giao dịch hoặc tổng giá trị = 0 trong điều kiện OR."
        );
        return false;
      }

      if (
        params.condition === "OR" &&
        ((params.total_trans.value == 0 && params.total_trans.op === "=") ||
          (params.trans_amount.value == 0 && params.trans_amount.op === "="))
      ) {
        this.notifyAlert(
          "warn",
          "Không được chọn số giao dịch hoặc tổng giá trị = 0 tại điều kiện AND đầu tiên. Không được chọn số giao dịch hoặc tổng giá trị = 0 trong điều kiện OR."
        );
        return false;
      }
      return true;
    },
    checkInputCustomer(params) {
      if (
        params.register_time.start_time > this.getCurrentDay() ||
        params.register_time.end_time > this.getCurrentDay()
      ) {
        this.notifyAlert("warn", "Khoảng thời gian phải nhỏ hơn ngày hiện tại");
        return false;
      }
      if (params.register_time.start_time > params.register_time.end_time) {
        this.notifyAlert("warn", "Ngày bắt đầu phải nhỏ hơn ngày kết thúc");
        return false;
      }
      return true;
    },
    store() {
      let checkInput = true;
      let dataFormMerchant = null;
      let dataFormService = null;
      let dataFormBank = null;
      let dataCustomer = null;
      let dataFormLoginSq = null;
      let dataGroups = null;
      if (
        this.formInput.title === null ||
        this.formInput.description === null
      ) {
        checkInput = false;
      }

      if (this.segment.value.length === 0) {
        this.notifyAlert("warn", "Tiêu chí không được bỏ trống");
        checkInput = false;
      }

      if (!this.formInput.description) {
        this.notifyAlert("warn", "Mô tả không được bỏ trống");
        checkInput = false;
      }

      if (!this.formInput.title) {
        this.notifyAlert("warn", "Tiêu đề không được bỏ trống");
        checkInput = false;
      }

      if (typeof this.$refs.formLoginSequent !== "undefined") {
        dataFormLoginSq = [];
        this.$refs.formLoginSequent.forEach((item) => {
          if (!this.checkInputLoginSq(item.loginsq)) {
            checkInput = false;
          }
          dataFormLoginSq.push(item.loginsq);
        });
        dataFormLoginSq = JSON.stringify(dataFormLoginSq);
      }

      if (typeof this.$refs.formMerchant !== "undefined") {
        dataFormMerchant = [];
        this.$refs.formMerchant.forEach((item) => {
          if (!this.checkInputMerchant(item.merchant)) {
            checkInput = false;
          }
          dataFormMerchant.push(item.merchant);
        });
        dataFormMerchant = JSON.stringify(dataFormMerchant);
      }
      if (typeof this.$refs.formService !== "undefined") {
        dataFormService = [];
        this.$refs.formService.forEach((item) => {
          if (!this.checkInputService(item.service)) {
            checkInput = false;
          }
          dataFormService.push(item.service);
        });
        dataFormService = JSON.stringify(dataFormService);
      }
      if (typeof this.$refs.formBank !== "undefined") {
        dataFormBank = [];
        this.$refs.formBank.forEach((item) => {
          if (!this.checkInputBank(item.bank)) {
            checkInput = false;
          }
          dataFormBank.push(item.bank);
        });
        dataFormBank = JSON.stringify(dataFormBank);
      }
      if (typeof this.$refs.formCustomer !== "undefined") {
        if (!this.checkInputCustomer(this.$refs.formCustomer.customer)) {
          checkInput = false;
        }
        dataCustomer = {
          register_time: {
            start_time:
              this.$refs.formCustomer.customer.register_time.start_time,
            end_time: this.$refs.formCustomer.customer.register_time.end_time,
          },
          kyc: this.$refs.formCustomer.customer.kyc,
        };

        if (!this.$refs.formCustomer.date_check) {
          delete dataCustomer.register_time;
        }
        if (!this.$refs.formCustomer.kyc_check) {
          delete dataCustomer.kyc;
        }

        dataCustomer = JSON.stringify(dataCustomer);
      }
      if (this.type.groups.condition.length > 0) {
        let ids = [];
        this.type.groups.condition.forEach((item) => {
          ids.push(item.value);
        });
        dataGroups = JSON.stringify(ids);
      }
      // check input
      if (!checkInput) {
        return;
      }
      if (
        dataCustomer === null &&
        dataFormMerchant === null &&
        dataFormService === null &&
        dataFormBank === null &&
        dataFormLoginSq === null &&
        dataGroups === null
      ) {
        this.notifyAlert("warn", "Thông tin tiêu chí không được bỏ trống");
        return;
      }
      this.$refs["myModal"].hide();
      let params = {
        title: this.formInput.title,
        description: this.formInput.description,
        dataCustomer: dataCustomer,
        dataMerchant: dataFormMerchant,
        dataService: dataFormService,
        dataBank: dataFormBank,
        dataLoginSq: dataFormLoginSq,
        dataGroups: dataGroups,
      };
      if (this.segmentDiff.value && this.segmentDiff.value.length > 0) {
        params.segment_diff = this.segmentDiff.value.map(
          (segment) => segment.id
        );
      }
      params = this.removeValidateNull(params);
      this.$bus.$emit("show-loading", true);
      CmsRepository.storeSegment(params)
        .then((response) => {
          if (response.data.error_code) {
            alert(response.data.message);
          }
          this.isGroups = false;
          this.segment.value = [];
          this.listAll();
          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          alert("Có lỗi xảy ra");
        });
    },
    storeByFile() {
      if (
        this.formInput.title === null ||
        this.formInput.description === null
      ) {
        this.notifyAlert("warn", "Tiêu đề và mô tả không được để trống");
        return false;
      }

      if (!this.file) {
        this.notifyAlert("warn", "Vui lòng chọn file");
        return false;
      }

      if (this.extensions.indexOf(this.file.type) === -1) {
        this.notifyAlert(
          "warn",
          "File không đúng định dạng, vui lòng kiểm tra lại"
        );
        return false;
      }

      if (this.file.size <= 0) {
        this.notifyAlert("warn", "File rỗng, vui lòng kiểm tra lại");
        return false;
      }

      this.$refs["customerCreate"].hide();
      let param = new FormData();
      param.append("file", this.file);
      param.append("title", this.formInput.title);
      param.append("description", this.formInput.description);

      this.$bus.$emit("show-loading", true);
      CmsRepository.storeSegmentByFile(param)
        .then((response) => {
          if (response.data.error_code) {
            this.$bus.$emit("show-loading", false);
            this.notifyAlert("warn", response.data.message);
            return false;
          }

          this.isGroups = false;
          this.segment.value = [];
          this.listAll();
          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          alert("Có lỗi xảy ra");
        });
    },
    deleteItem(id) {
      let params = {
        id: id,
      };
      if (!confirm("Bạn chắc chắn muốn xóa chứ?")) {
        return;
      }
      CmsRepository.deleteSegment(params)
        .then((response) => {
          if (response.data.error_code) {
            alert(response.data.message);
          } else {
            this.notifyAlert("success", "Xóa thành công");
          }
          this.listAll();
          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          alert("Có lỗi xảy ra");
        });
    },
    cloneItem(item) {
      this.$bus.$emit("show-loading", true);
      this.titleCreate = "Clone segment từ " + item.title;

      this.formInput.id = null;
      this.formInput.title = item.title;
      this.formInput.description = item.description;

      // Loại trừ Segment đã tạo
      this.segmentDiff.value = [];
      if (item.segment_diffs.length > 0) {
        CmsRepository.listAllSegment({
          ids: item.segment_diffs,
        })
          .then((response) => {
            if (response.data.error_code) {
              alert(response.data.message);
            } else {
              this.segmentDiff.value = response.data.data.data;
            }
          })
          .catch(() => {
            alert("Có lỗi xảy ra");
          });
      }

      this.segment.value = [];

      // Tiêu chí (Nhóm khách hàng)
      this.type.groups.condition = [];
      if (item.groups?.length > 0) {
        this.segment.value.push({ value: "GROUPS", text: "Nhóm khách hàng" });
        this.type.groups.condition = item.groups.reduce((acc, group) => {
          const groupItem = this.list_group.find(
            (gr) => parseInt(gr.value) === parseInt(group.group_id)
          );
          if (groupItem) {
            acc.push({ value: groupItem.value, text: groupItem.text });
          }

          return acc;
        }, []);
      }
      // Tần suất đăng nhập
      this.valueFormLoginSq = [];
      if (item.login_details) {
        this.editLoginSequent = true;
        this.segment.value.push({
          value: "LOGINSEQUENT",
          text: "Tần suất đăng nhập",
        });
        const loginDetail = JSON.parse(item.login_details);
        if (loginDetail[0] && loginDetail[0][0]) {
          // nested
          this.valueFormLoginSq = loginDetail[0];
        } else {
          this.valueFormLoginSq = loginDetail;
        }
      }

      this.customer = {
        register_time: {
          start_time: this.getYesterday(),
          end_time: this.getCurrentDay(),
        },
        kyc: false,
      };

      if (item.user_props) {
        this.date_check = false;
        this.kyc_check = false;
        this.segment.value.push({
          value: "CUSTOMER",
          text: "Thuộc tính khách hàng",
        });
        const user_props = JSON.parse(item.user_props);

        if (user_props.hasOwnProperty("register_time")) {
          this.date_check = true;
          this.customer.register_time = user_props.register_time;
        }

        if (user_props.hasOwnProperty("kyc")) {
          this.kyc_check = true;
          this.customer.kyc = user_props.kyc;
        }
      }

      this.valueFormMerchant = [];
      if (item.trans_props) {
        this.editTransaction = true;
        this.segment.value.push({
          value: "TRANSACTION",
          text: "Theo giao dịch",
        });

        this.valueFormMerchant = JSON.parse(item.trans_props);
      }

      this.valueFormBank = [];
      if (item.bank_props) {
        this.editBank = true;
        this.segment.value.push({ value: "BANK", text: "Liên kết ngân hàng" });
        this.valueFormBank = JSON.parse(item.bank_props);
      }

      this.addStandard();

      this.formInput.status.value = 0;
      this.$bus.$emit("show-loading", false);
      this.$refs["myModal"].show();
    },
    loadGroups() {
      CmsRepository.getUserGroups().then((response) => {
        if (response.data.error_code) {
          alert(response.data.message);
        } else {
          response.data.data.forEach((item) => {
            this.list_group.push({
              value: item.id,
              text: item.name,
            });
          });
        }
      });
    },
    listAll() {
      this.$bus.$emit("show-loading", true);
      let numberPage = 1;
      if (this.$route.query.page !== "undefined") {
        numberPage = this.$route.query.page;
      }

      this.items = [];
      let params = this.convert(this.filter);
      params.page = numberPage;

      CmsRepository.listAllSegment(params)
        .then((response) => {
          if (response.data.error_code) {
            alert(response.data.message);
          } else {
            this.items = response.data.data.data;
            this.paginate.total = response.data.data.total;
            this.paginate.totalPage = response.data.data.last_page;
            this.paginate.currentPage = response.data.data.current_page;
            this.notifyAlert("success", "Lấy dữ liệu thành công");
          }
          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          alert("Có lỗi xảy ra");
        });
    },
    convert(params) {
      Object.keys(params).forEach((key) => {
        if (params[key] === null || params[key] == "") {
          delete params[key];
        }
      });
      return params;
    },
    showUpdate(item) {
      this.detail.id = item.id;
      this.detail.title = item.title;
      this.detail.status = item.status;
      this.detail.created_by = item.created_by;
      this.detail.description = item.description;
      this.detail.segment_users_count = item.segment_users_count;
      this.detail.trans_props = item.trans_props;
      this.detail.bank_props = item.bank_props;
      this.detail.user_props = item.user_props;
      this.detail.segment_diff = item.segment_diff;
      this.detail.segment_diffs = item.segment_diffs;
      this.detail.loginsequent = item.login_details;
      this.$refs["detailSegment"].show();
    },
    addForm(typeCond) {
      let parmas = {
        type: this.type[typeCond].value,
        condition: this.type[typeCond].condition.value,
      };

      let form;
      switch (typeCond) {
        case "transaction":
          this.editTransaction = false;
          form = this.valueFormMerchant;
          break;
        case "bank":
          this.editBank = false;
          form = this.valueFormBank;
          break;
        case "loginsequent":
          this.editLoginSequent = false;
          form = this.valueFormLoginSq;
          break;
        default:
          alert("Có lỗi xảy ra");
      }

      form.push(parmas);
    },
    addStandard() {
      this.is_Transaction = false;
      this.is_Customer = false;
      this.is_Bank = false;
      this.isLoginSequent = false;
      this.isGroups = false;
      this.segment.value.forEach((item) => {
        if (item.value === "CUSTOMER") {
          this.is_Customer = true;

          if (!this.editTransaction) {
            this.valueFormMerchant = [];
          }
        }
        if (item.value === "BANK") {
          this.is_Bank = true;
        }
        if (item.value === "TRANSACTION") {
          this.is_Transaction = true;
        }

        if (item.value === "LOGINSEQUENT") {
          this.isLoginSequent = true;
        }
        if (item.value === "GROUPS") {
          this.isGroups = true;
        }
      });

      if (!this.is_Transaction) {
        this.valueFormMerchant = [];
      }
      if (!this.is_Customer) {
        this.customer = {
          register_time: {
            start_time: this.getYesterday(),
            end_time: this.getCurrentDay(),
          },
          kyc: false,
        };
      }
      if (!this.is_Bank) {
        this.valueFormBank = [];
      }
      if (!this.isLoginSequent) {
        this.valueFormLoginSq = [];
      }
      if (!this.isGroups) {
        this.type.groups.condition = [];
      }
    },
    bankClose(key) {
      this.valueFormBank.splice(key, 1);
    },
    TransactionClose(key) {
      this.valueFormMerchant.splice(key, 1);
    },
    LoginSqClose(key) {
      this.valueFormLoginSq.splice(key, 1);
    },
    asyncFind(search) {
      this.segmentDiff.option = [];
      let params = {
        search: search,
      };
      CmsRepository.listAllSegment(params)
        .then((response) => {
          if (response.data.error_code) {
            alert(response.data.message);
          } else {
            this.segmentDiff.option = response.data.data.data;
          }
        })
        .catch(() => {
          alert("Có lỗi xảy ra");
        });
    },
    checkFile() {
      if (!this.file) {
        this.stage = false;
        this.notifyAlert("warn", "Vui lòng chọn file");
        return false;
      }

      if (this.extensions.indexOf(this.file.type) === -1) {
        this.stage = false;
        this.notifyAlert(
          "warn",
          "File không đúng định dạng, vui lòng kiểm tra lại"
        );
        return false;
      }

      if (this.file.size <= 0) {
        this.stage = false;
        this.notifyAlert("warn", "File rỗng, vui lòng kiểm tra lại");
        return false;
      }

      this.stage = true;
      return true;
    },
  },
  watch: {
    file() {
      this.checkFile();
    },
    currentPage() {
      this.listAll();
    },
  },
  created() {
    this.loadGroups();
    this.listAll();
    this.asyncFind("");
  },
};
</script>
<style>
.marginLeft {
  margin-left: 15px;
}

.bluex {
  color: #6262ff;
}

.red {
  color: red;
}

button.floatRight {
  float: right;
  margin-top: 20px;
}

button.floatRight i {
  color: red;
}
</style>
