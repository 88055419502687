<template>
  <div>
    <b-card bg-variant="light">
      <div class="d-inline-flex">
        <legend>Theo khách hàng</legend>
        <div class="ml-4 w-100">
          <select class="form-control" @change="handleCheck($event)">
            <option :selected="date_check && kyc_check" value="all">
              Sử dụng cả 2 điều kiện Đăng ký và KYC
            </option>
            <option :selected="date_check && !kyc_check" value="date">
              Chỉ sử dụng điều kiện Đăng ký
            </option>
            <option :selected="!date_check && kyc_check" value="kyc">Chỉ sử dụng điều kiện KYC</option>
          </select>
        </div>
      </div>
      <hr />
      <b-row>
        <b-col v-if="date_check">
          <b-form-group label="Đăng ký ví từ ngày (*)">
            <b-form-input
              :id="`type-date`"
              :type="`date`"
              v-model="customer.register_time.start_time"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col v-if="date_check">
          <b-form-group label="Đến ngày (*)">
            <b-form-input
              :id="`type-date`"
              :type="`date`"
              v-model="customer.register_time.end_time"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col v-if="kyc_check">
          <b-form-group label="Xác thực (*)">
            <b-form-select
              v-model="customer.kyc"
              :options="kyc.options"
              required
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import Vue from "vue";
import Multiselect from "vue-multiselect";

Vue.component("multiselect", Multiselect);

export default {
  props: ["customerDad", "dateCheck", "kycCheck"],
  data() {
    return {
      date_check: this.dateCheck ?? false,
      kyc_check: this.kycCheck ?? false,
      customer: this.customerDad,
      kyc: {
        options: [
          { value: false, text: "Chưa xác thực" },
          { value: true, text: "Xác thực" },
        ],
      },
    };
  },
  created() {
    if (this.dateCheck && this.kycCheck) {
      this.check("all");
    } else if (this.dateCheck) {
      this.check("date");
    } else if (this.kycCheck) {
      this.check("kyc");
    }
  },
  methods: {
    handleCheck(event) {
      this.check(event.target.value);
    },
    check(option) {
      if (option === "all") {
        this.date_check = true;
        this.kyc_check = true;
      }
      if (option === "date") {
        this.date_check = true;
        this.kyc_check = false;
      }
      if (option === "kyc") {
        this.kyc_check = true;
        this.date_check = false;
      }
    },
  },
};
</script>