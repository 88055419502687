<template>
  <div>
    <b>{{ condition }}</b>
    <b-card bg-variant="light">
      <b-row>
        <b-col>
          <b-form-group label="Trạng thái liên kết">
            <b-form-select
              v-model="bank.affiliated"
              :options="affiliatedOption"
              required
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            v-if="!this.isShow.from"
            label="User có đăng nhập ví từ ngày"
          >
            <b-form-input
              :id="`type-date`"
              :type="`date`"
              v-model="bank.register_time.start_time"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group v-else label="Từ ngày">
            <b-form-input
              :id="`type-date`"
              :type="`date`"
              v-model="bank.register_time.start_time"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Đến ngày">
            <b-form-input
              :id="`type-date`"
              :type="`date`"
              v-model="bank.register_time.end_time"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="this.isShow.from">
        <b-col>
          <b-form-group label="Loại liên kết">
            <b-form-select
              v-model="bank.linkType"
              :options="linkTypeOption"
              required
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Danh sách ngân hàng (*)">
            <multiselect
              v-model="bank.value"
              :options="option"
              :multiple="true"
              label="text"
              track-by="value"
              required
            >
              <b-form-select-option :value="null">Tất cả</b-form-select-option>
            </multiselect>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import Vue from "vue";
import Multiselect from "vue-multiselect";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";
const CmsRepository = RepositoryFactory.get("cms");
Vue.component("multiselect", Multiselect);

export default {
  props: ["condition", "indexKey", "listBank", "bankProp", "editBank"],
  mixins: [Common],
  data() {
    return {
      bank: {
        define: "BANK",
        register_time: {
          start_time: this.getYesterday(),
          end_time: this.getCurrentDay(),
        },
        affiliated: false,
        linkType: "DIRECT_LINK",
        value: [],
        condition: this.condition,
        indexKey: this.indexKey,
      },
      affiliatedOption: [
        { value: "NOT_LINK", text: "Chưa liên kết" },
        { value: "LINK", text: "Đã liên kết" },
      ],
      linkTypeOption: [
        { value: "DIRECT_LINK", text: "Liên kết trực tiếp" },
        { value: "LINK_NAPAS", text: "Liên kết thông qua Napas" },
      ],
      option: [],
      isShow: {
        from: true,
      },
    };
  },
  methods: {
    getListBank() {
      CmsRepository.listAllBank()
        .then((response) => {
          if (response.data.error_code) {
            alert(response.data.message);
          } else {
            this.option = response.data.data;
          }
          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          alert("Có lỗi xảy ra");
        });
    },
  },
  created() {
    if (this.editBank) {
      this.bank = this.bankProp;
    }
    this.getListBank();
  },
  watch: {
    "bank.affiliated"() {
      if (this.bank.affiliated === "NOT_LINK") {
        this.isShow.from = false;
      } else {
        this.isShow.from = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
button.btn.btn-danger {
  margin-left: 5px;
}
</style>
